// Accessibility style overwrites. Add as body class.

a.skip-link {
  position: absolute;
  overflow: hidden;
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

a.skip-link:focus {
  position: static;
  width: auto;
  height: auto;
  clip: auto;
  padding: 0.5rem;
  border: 1px solid;
  text-align: center;
}

.a11y {

  .form-control, .custom-control-label::before {
    border-color: #657886;
  }

  .text-legal {
    opacity: 1 !important;
  }

  .watermark {
    opacity: 1 !important;
  }

  .text-muted {
    color: inherit !important;
    font-style: italic;
  }

  .fc-event {
    // full calendar
    &.active {
      background: $primary;
      color: $theme-contrast-color;
      &:hover{
        td {
          background-color: lighten($primary, 10%);
        }
      }
    }
    &.inactive {
      opacity: 1 !important;
    }
  }

  .card-footer {
    // else things like remove buttons (will have insufficient contrast)
    background-color: inherit !important;
  }
}

.dropdown-toggle:after {
  display:none;
}
