@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Other imports
@import "include/cookieconsent";
@import "include/extended-utils";
@import "~hamburgers/_sass/hamburgers/hamburgers";
@import 'include/psp-selector.scss';

// Plugins
@import "~bs-stepper/dist/css/bs-stepper.min.css";

.text-legal, .opacity-5 {
  opacity: .5;
}

@import "include/a11y";
