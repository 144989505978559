/*
 * Link variants (e.g. ".link-success")
 * Copied from https://github.com/sera-business-design/bootstrap-extensions
 */
@mixin link-variant($color) {
  color: $color;

  @include hover {
    color: darken($color, 15%);
  }
}

@each $color, $value in $theme-colors {
  a.link-#{$color} {
    @include link-variant($value);
  }

  .links-#{$color} a {
    @include link-variant($value);
  }
}
