// Theme colors
$primary: #543593 !default;
$theme1: #543593;
$theme2: #FC7E27;
$theme3: #3E3E3E;
$theme4: rgba(0, 0, 0, 0.125);
$theme-contrast-color: #fff !default;

$theme-colors: (
    'theme1': $theme1,
    'theme2': $theme2,
    'theme3': $theme3,
    'theme4': white
) !default;

$default-theme-colors: (
        'contrast': $theme-contrast-color,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $default-theme-colors);

$border-radius: 8px;
$btn-border-radius: 3px;

$input-border-radius: 0;
$input-border-width: 2px;
$input-border-color: $theme1;
$custom-select-border-radius: 0;

$line-height-lg: 2.5;
$card-border-width: 2px;


@import '../../../../src/scss/main.scss';

@import "~daterangepicker";

$lg-path-fonts: '~lightgallery/fonts';
$lg-path-images: '~lightgallery/images';
@import "~lightgallery/scss/lightgallery";
@import "~lightgallery/scss/lg-video";

h1, h2, h3, h4, h5 {
  color: $theme1;
}

h4 {
  @extend .mt-3;
  font-size: 1.2rem;
  font-weight: 600;
}

h5 {
  @extend .mt-2;
  font-size: 1rem;
  font-weight: 600;
}

.list-unstyled {
  li {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}

.card {
  background: none;
}

.header-serperator {
  width: 180px;
  margin-bottom: 3em;
}

a {
  color: $theme2;
}

.hamburger-inner {
  background-color: $theme1;
  &:before, &:after {
    background-color: $theme1;
  }
}

.socials {
  margin-top: 4px;
  .fab {
    font-size: 1.4em;
  }
}

.add-to-cart-small {
  .form-control {
    @extend .w-100;
  }
}

.grecaptcha-badge {
  display: none;
}

.slide {
  background-color: white;
}

.featured {
  @extend .mb-4;
  img {
    max-width: 200px;
    height: auto;
    border-radius: $border-radius;
    margin-bottom: 1em;
    width: 100%;
  }
}

.badge-pill {
  margin-left: -10px
}

.footer-banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .col {
    margin-top: 10em;
    padding: 20px;
    margin-bottom: 3em;
    background-color: white;
    border-radius: $btn-border-radius;
    @include media-breakpoint-down(sm) {
      opacity: 90%;
    }
    a {
      color: #fc7e27;
      :hover {
        color: #3E3E3E;
      }
    }
  }
}

.btn-cart {
  i {
    @extend .ml-2;
  }
}

.d-md-flex {
  .container {
    min-width: 450px;
  }
}

table {
  th {
    font-weight: 400;
    color: $theme1;
    border-right: 1px solid #484848 !important;
  }
}

.product-order-by {
  label {
    color: grey;
    @extend .mt-0;
    font-weight: 400;
  }
  select, input, textarea {
    background: none;
    border: none;
    font-weight: 600 !important;
  }
}

.footer {
  color: white;
  padding-top: 2em;
  h3, h2 {
    color: white;
    text-transform: capitalize;
  }
  td, th {
    color: white;
    width: 50%;
  }
  .row div {
    padding-top: 2em;
    padding-bottom: 2em;
  }
  .copyright {
     background-color: rgba(255, 255, 255, 0.2);
  }
  a {
    color: white;
    text-decoration: underline;
    :hover {
      color: $theme2;
    }
  }
  .btn {
    -webkit-box-shadow: 0px 4px 0px 0px rgba(252,126,39,0.5);
    box-shadow: 0px 4px 0px 0px rgba(252,126,39,0.5);
    text-decoration: none;
  }
}

.flavor-shape-bottom {
  margin-bottom: -1px;
}
.flavor-shape-top {
  margin-top: -1px;
}

.breadcrumb {
  @extend .px-0;
  background: none;
  a {
    color: $theme1;
  }
}
.bs-stepper {
  max-width: 700px;
  .active .bs-stepper-circle {
    background-color: $theme1;
  }
}

.breadcrumb-item + .breadcrumb-item {
  &:before {
    padding-right: 1.2rem;
    padding-left: 0.7rem;
    color: $theme2;
    content: ">";
  }
  .active {
    a {
      color: $theme2;
    }
  }
}

.product-description, .product-card {
  input, select, textarea {
    border-color: $theme2;
  }
}

.block-content {
  .list-unstyled {
    li {
      @extend .my-3;
    }
  }
}

.btn-theme2 {
  @extend .text-white;
}

.hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .img-0 {
    max-width: 400px;
    height: 250px;
  }
  .img-1 {
    max-width: 280px;
    height: 200px;
    margin-left:auto;
  }
  .img-2 {
    max-width: 250px;
    height: 150px;
  }
  .row {
    height: 30em;
  }
  @include media-breakpoint-down(md) {
    .img-0, .img-1, .img-2 {
      margin-top: 2em;
      margin-bottom: 1em;
      width: 100%;
      height: 200px;
    }
    .row {
      height: auto;
    }
  }
  p {
    line-height: $line-height-lg;
  }
  .screen {
    background-color: rgba(255, 255, 255, 0.9);
  }
  .hero-img {
    border-radius: $border-radius;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .btn {
    color: white !important;
  }
}

.text-max {
  max-width: 550px;
}

.duo {
  background-color: #fc7e27;
  color: white;
  .col, .col-6 {
    padding-left: 2em;
  }
  .img-half {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    min-height: 400px;
    @include media-breakpoint-down(sm) {
      min-height: 200px;
      max-height: 300px;
    }
  }
  p {
    line-height: 2em;
  }
  h1,h2,h2 {
    color: white;
  }
}

.topbar {
  font-size: 80%;
  a {
      @extend .link-theme4;
  }
  .dropdown-menu {
    margin-top: 12px;
    border-right: 0 0 $btn-border-radius $btn-border-radius;
  }
  .dropdown-item {
    @extend .link-primary;
  }
}

.carousel {
  img {
    max-height: 400px;

  }
}

.quantity-input {
  i {
    color: white;
  }
  .form-outline {
    width: 60px;
    input {
      text-align: center;
      padding: 0;
      background: white;
      border: 0;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.social-icons {
  font-size: 1.5em;
}

.info-bar--background {
  background-color: $theme2;
}

.card-img-top {
  border-radius: $btn-border-radius $btn-border-radius 0 0;
}

.flex-space {
  align-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.product-img {
  max-width: 200px;
}

.vl {
  border-left: 2px solid black;
  opacity: 80%;
  height: 25px;
}

.topnav {
  padding: 10px;
  background-color: $theme1;
  overflow: hidden;
  height: 40px;
  padding: 8px;
  a {
    color: white;
    padding-right: 20px;
  }
  .fa-inverse {
    color: $theme2;
  }
}

.navbar {
  .navbar-brand img {
    padding: 0.6em;
    height: 90px;
  }
  .nav-link:not(.nav-select) {
    color: $theme1 !important;
    font-weight: 600;
  }

  .social-icons {
    margin-left: 10px;
    display: inline-block;

    a {
      background-color: white;
      text-align: center;
      padding-top: 6px;
      color: $theme2 !important;
      font-size: 11px;
      border-radius: 15px;
      align-content: center;
      display: inline-block;
      width: 25px;
      height: 25px;

      i {
        font-size: 14px;
      }

    }

    :hover {
      background-color: #dfdfdf;
    }

  }
}

.navbar .active .nav-link {
  color: $theme2 !important;
}

.main-img {
  border-radius: $border-radius;
  @extend .mb-3;
}

.ups-link {
  color: white;
}
.ups-link span {
  position: absolute;
  margin-left: 2.2em;
}
.ups-link .fa-stack {
  font-size: 0.8em;
  margin-left: 0;
}

.nav-select {
  @extend .nav-link;
  color: white !important;
}

.page-banner {
  height: 200px;
  width: 100%;
  border-radius: $border-radius;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

hr {
  border: 0;
  height: 2px;
  position: relative;
  margin: 0px auto 20px auto;

  &.center-square {
    height: 2px;
    width: 25%;
    background: $theme3;

    &:before {
      content: "";
      width: 50px;
      height: 12px;
      background: $theme2;
      display: inline-block;
      border: 2px solid $theme2;
      position: absolute;
      top: -5px;
      left: 50%;
      margin: 0 0 0 -25px;
    }
  }

  &:not(.center-square) {
    background: $theme4;

    &:before {
      content: "";
      width: 20%;
      height: 5px;
      background: $theme2;
      display: inline-block;
      border: 2px solid $theme2;
      position: absolute;
      top: -2px;
      margin: 0;
    }
  }
}

.product-card {
  border:none;
  border-radius: $btn-border-radius;
  -webkit-box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.2);
  .row {
    justify-content: center;
    align-items: center;
  }
}

.stacked-links-wrapper {
  .btn {
    @extend h4;
    border-radius: 0 !important;
    :focus {
      box-shadow: none !important;
    }
  }
  a {
    &.has-child {
      @extend .pl-3;
    }
  }
  ul {
    li {
      @extend .m-0;
      @extend .p-2;
      :not(:active) {
        @extend .text-theme1;
      }
      &.active {
        @extend .bg-theme2;
        a {
          color: white !important;
        }
      }
    }
  }
}


label {
  @extend h5;
}

.parsedContent {
  table {
    width: 100%;
    @extend .table;
    @extend .table-borderless;
    @extend .table-striped;
  }
}

.importantInput {
  display: none !important;
}
