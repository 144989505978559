// Bootstrap variable overrides
//$enable-responsive-font-sizes: true !default;

// WCAG 2.1 AA compliance. Important, do not change lightly!
$success: #218739;
$info: #128091;
// end WCAG 2.1 compliance block

$theme-contrast-color: #fff !default;
$theme1: #543593;
$theme2: #FC7E27;
$theme3: #3E3E3E;

$theme-colors: (
        'facebook': #1778F2,
        'twitter': #1DA1F2,
        'google-plus': #4285F4,
        'instagram': #D7005A,
        'linkedin': #0077b5,
        'pinterest': #e60023,
        'tumlbr': #35465c,
        'youtube': #FF0000,
) !default;

$default-theme-colors: (
        'contrast': $theme-contrast-color,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $default-theme-colors);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1900px
);

$custom-file-text: (
        en: "Browse",
        nl: "Bladeren"
) !default;

$rfs-base-font-size: .9rem !default;

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
                (
                        0: 0,
                        1: ($spacer * .25),
                        2: ($spacer * .5),
                        3: $spacer,
                        4: ($spacer * 1.5),
                        5: ($spacer * 3)
                ),
                $spacers
);

$btn-border-radius:           50rem !default;
$btn-border-radius-lg:        50rem !default;
$btn-border-radius-sm:        50rem !default;


$btn-padding-x:               map_get($spacers, 3) !default;
$btn-padding-x-sm:            map_get($spacers, 4) * .5 !default;
$btn-padding-x-lg:            map_get($spacers, 4) !default;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Hamburgers
//$hamburger-layer-width: 20px;
//$hamburger-layer-height: 3px;
//$hamburger-layer-spacing: 4px;
$hamburger-layer-color: $gray-600 !default;
$hamburger-active-layer-color: $gray-800 !default;
$hamburger-hover-opacity: 1 !default;
//$hamburger-hover-use-filter: true !default;

$hamburger-types: (
        squeeze
) !default;
